import { EnsambladosControllerApi, SustitutosControllerApi, settings} from '@api/backend'
import { displaySuccessMsg } from '@src/utility/Utils'

const prefix = 'ensamblados'

export const GET_ENSAMBLADO = `${prefix}/GET_DATA_ensamblados`
export const GET_ENSAMBLADOS = `${prefix}/GET_DATA`
export const GET_ENSAMBLADOS_ALL = `${prefix}/GET_DATA_ALL`
export const GET_ENSAMBLADOS_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_ENSAMBLADO = `${prefix}/ADD`
export const DELETE_ENSAMBLADO = `${prefix}/DELETE`
export const UPDATE_ENSAMBLADO = `${prefix}/UPDATE`
export const GET_FILTROS_ENSAMBLADOS = `${prefix}/GET_FILTROS_ENSAMBLADOS`
export const INIT_DATA_NECESARIA = `${prefix}/INIT_DATA_NECESARIA`

const apiEnsamblados = new EnsambladosControllerApi(settings)
const apiSutitutos = new SustitutosControllerApi(settings)

export const getEnsamblados = params => {
  return async (dispatch, getState) => {
    try {
      const { page = 1, perPage = 10, filtrosBusqueda, listaDeTodosLosProductos } = params
      const { skuPrincipal, skuEnsamblado, descripcion, cantidad } = filtrosBusqueda || {}
      const inicio = ((page - 1) * perPage)
      const fin = inicio + perPage

      // const empresaId = getState().layout.selectedEmpresaId
      
      const filter = {
        where: {
          and: []
        }
      }
      
      // Siempre incluimos estos filtros ya que tienen valores por defecto
      filter.where.and.push({ skuPrincipal: { like: skuPrincipal ? `%${skuPrincipal}%` : '%%' } })
      filter.where.and.push({ skuEnsamblado: { like: skuEnsamblado ? `%${skuEnsamblado}%` : '%%' } })
      filter.where.and.push({ descripcion: { like: descripcion ? `%${descripcion}%` : '%%' } })
      
      // Solo incluimos estos filtros si tienen valores proporcionados
      if (cantidad) {
        filter.where.and.push({ cantidad: { eq: `${cantidad}` } })
      }

      const {data: dataEnsamblados} = await apiEnsamblados.ensambladosControllerFind(JSON.stringify(filter))
      const registrosPaginados = dataEnsamblados.slice(inicio, fin) // -> Filtra pagina
      
      const agregarTitulos = (lstTodosLosProductos, lstRegistros) => {
        return lstRegistros.map(item => {
          const objSKUPrincipal = lstTodosLosProductos.find(obj => obj.sku === item.skuPrincipal)
          const objSKUEnsamblado = lstTodosLosProductos.find(obj => obj.sku === item.skuEnsamblado)
      
          return {
            ...item,
            tituloSkuPrincipal: objSKUPrincipal ? objSKUPrincipal.titulo : '',
            tituloSkuEnsamblado: objSKUEnsamblado ? objSKUEnsamblado.titulo : ''
          }
        })
      }
      
      const registrosPaginadosConTitulos = agregarTitulos(listaDeTodosLosProductos, registrosPaginados)

      dispatch({
        type: GET_ENSAMBLADOS,
        ensamblados: registrosPaginadosConTitulos,
        total: dataEnsamblados.length || 0
      })

      //Guardamos los filtros de busqueda (Inputs)
      dispatch({
        type: GET_FILTROS_ENSAMBLADOS,
        filtros: filtrosBusqueda
    })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const addEnsamblado = (ensamblado, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiEnsamblados.ensambladosControllerCreate(ensamblado)
      displaySuccessMsg(intl.formatMessage({ id: 'Ensamblado creado' }))
      dispatch({
         type: ADD_ENSAMBLADO,
         data
       })
    } catch (err) {
      console.error(err.message)
      console.log(err.message)
    }
  }
}

export const updateEnsamblado = (id, ensamblado, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiEnsamblados.ensambladosControllerUpdateById(id, ensamblado)
      displaySuccessMsg(intl.formatMessage({ id: 'Ensamblado actualizado' }))
      dispatch({
         type: UPDATE_ENSAMBLADO,
         data
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const deleteEnsamblado = (ensambladoId, intl, datosRegistro) => {
  return async (dispatch) => {
    try {
      const {data} = await apiEnsamblados.ensambladosControllerDeleteById(ensambladoId, datosRegistro.skuPrincipal)
      displaySuccessMsg(intl.formatMessage({ id: 'Ensamblado borrado' }))
       dispatch({
         type: DELETE_ENSAMBLADO,
         data: ensambladoId
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getEnsamblado = (ensamblado) => {
  return async (dispatch) => {
    try {
      const {data: dataEnsamblado} = await apiEnsamblados.ensambladosControllerFindById(ensamblado)
      dispatch({
        type: GET_ENSAMBLADO,
        selectedEnsamblado: dataEnsamblado
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const initEnsamblado = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ENSAMBLADO,
      selectedCompany: null
    })
  }
}

export const obtenerSkuPrincipales = () => {
  return async () => {
    try {
      const {data: listaSKUs} = await apiSutitutos.sustitutosControllerObtenerSkuPrincipales()
      return listaSKUs
    } catch (err) {
      console.error(err.message)
    }
  }
}
